/* You can add global styles to this file, and also import other style files */


@import './scss/material';
@import './scss/utils';
@import './scss/responsive';
@import './scss/variables';
@import './scss/theme';

html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

html {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  overflow: auto;
}

body {
  padding: 0;
  // padding: 1rem;
  overflow: auto;
}

.mat-elevation-z8 {
  overflow-x: auto;
}

.icon-lg {
  font-size: 40px;
}

.responsiveTable .paginate_button {
  font-weight: 500 !important;
}

.mat-card {
  &.danger {
    background: linear-gradient(60deg, #ec407a, #d81b60);
  }

  &.warn {
    background: linear-gradient(60deg, #ffa726, #fb8c00);
  }

  &.success {
    background: linear-gradient(60deg, #66bb6a, #43a047);
  }

  &.info {
    background: linear-gradient(60deg, #26c6da, #00acc1);
  }
}

.full-width {
  width: 100%;
}

.action-button {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  opacity: 0.7;
}

.action-button:hover {
  opacity: 1;
}

.action-button.edit {
  background-color: #008cba;
  /* Blue */
}

.action-button.update {
  background-color: #4caf50;
  /* Green */
}

.action-button.delete {
  background-color: #f44336;
  /* Red */
}

.action-button.cancel {
  background-color: #e7e7e7;
  /* Gray */
  color: black;
}

.flright {
  text-align: right;
  width: 100%;
}

.mat-card-header .mat-card-title {
  white-space: nowrap;
}

// ============== DATA TABLE ===============

.responsiveTable {
  font-size: 14px;

  // .dataTables_scroll {
  //   box-shadow: 0 2px 4px 0 rgba(55, 67, 82, 0.2);
  // }
  .paginate_button {
    border-radius: 50% !important;
    // padding: 4px 10px !important;
    $size: 25px;
    width: $size;
    height: $size;
    padding: 0 !important;
    font-size: 13px;
    font-weight: bold;
    line-height: 23px;
  }

  .previous,
  .next {
    width: auto !important;
    border-radius: 0 !important;
  }

  .buttons-copy,
  .buttons-excel,
  .buttons-print {
    border: 1px solid #fff !important;
    background-image: none !important;
    color: #fff;
    transition: 150ms linear;
  }

  .buttons-copy {
    &:hover {
      opacity: 0.8;
      background-color: #ff9800 !important;
    }

    background-color: #ff9800;
  }

  .buttons-excel {
    &:hover {
      opacity: 0.8;
      background-color: #3759B9 !important;
    }

    background-color: #FDBC02;
    border-radius: 3px;
  }

  .buttons-print {
    &:hover {
      opacity: 0.8;
      background-color: #ff5722 !important;
    }

    background-color: #ff5722;
  }

  .dataTables_scrollBody {
    border: none !important;
  }

  input[type="search"] {
    border: 1px solid#ccc;
    border-radius: 2px;
    height: 34px;
  }

  .buttons-csv {
    display: none !important;
  }

  div.dt-buttons {
    float: right;
    padding-left: 10px;
  }

  .dataTables_wrapper .dataTables_filter input {
    margin-bottom: 0.333em !important;
  }

  @media screen and (max-width: 600px) {
    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
      }

      td {
        border-bottom: 1px solid #ddd;
        display: block;
        text-align: right;

        &::before {
          content: attr(data-title);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .dataTables_wrapper .dataTables_filter {
      margin-top: 0.5em;
      margin-bottom: 0.9em;
    }

    .dataTables_wrapper .dataTables_filter input {
      margin-bottom: 0.333em !important;
    }
  }

  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc {
    background-image: none;
  }

  table.dataTable thead th {
    padding: 10px 18px;
    border-bottom: 1px solid #272468;
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: 500;
  }

  thead th {
    background: #272468;
    color: #fff !important;
    white-space: nowrap;

  }

  .current {
    thead th {
      background: #272468;
      color: #fff !important;
    }
  }

  .completed {
    thead th {
      background: #18b17a;
      color: #fff !important;
    }
  }

  table {
    width: 100% !important;

    tr {
      background-color: #f8f8f8;
      border: none;

      &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    th,
    td {
      border: 1px solid #dee2e6;
    }
  }
}

.dashboardtable .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  height: 172px !important;
}

.dashboardtable .dataTables_scrollHeadInner {
  display: contents !important;
}

.eclipsetxt {
  display: flex;
  display: -webkit-box;
  text-decoration: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
}

.mat-dialog-container {
  overflow-y: hidden !important;
}