
/* =============== Scrollbar Styling Start ============= */
img{ max-width: 100%;}
::-webkit-scrollbar {
    width: 7px;
}
 
::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #aaaaaa; 
}

/* =============== Scrollbar Styling End ============= */
.nav-brand{ margin-top: 10px;}
.mat-toolbar.mat-primary{
    background: #272468;
}
.ag-header {
    background-color: #272468;
    height: 40px !important;
    min-height: 40px !important;
}
.ag-header-row {  height: 40px; }

.mat-form-field.mat-focused .mat-form-field-label{
    color: #2720b3;
}

.mat-form-field.mat-focused .mat-form-field-ripple{
    background-color: #2720b3;
}

.mat-card-header{ margin-top: 10px;}
.mat-card-content{ margin-top: 35px;}

.ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover {
    background-color: transparent;
}
.ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover{
    background-color: transparent;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent{
    color: #ffffff;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #9f9f9f6b;
}

.mat-toolbar .mat-form-field-underline, .mat-toolbar .mat-form-field-ripple, .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor !important;
}
.mat-card-header-text{ margin: 0px !important;}

.ag-header-icon {
    color: #ffffff;
}

.ag-header-cell-text {
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
    text-overflow: initial;
}

.action-button.edit {
    background-color: #28a745;
}

.action-button.delete {
    background-color: #dc3545;
}

.action-button {
    border: none;
    color: #fff;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    opacity: 1;
    padding: 5px 18px;
    border-radius: 3px;
    font-weight: 600;
}

.ag-center-cols-viewport {
    overflow-x: hidden;
}

.dflex100{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody{
    padding-bottom: 20px !important;
}

.l44{ line-height: 42px !important;}




/* ======= Profile Dropdown Start =========== */
.mat-menu-item {
    line-height: 30px !important;
    height: 30px !important;
    padding: 0 8px !important;
    font-size: 13px !important;
}
.mat-menu-item .mat-icon {
    margin-right: 8px !important;
    margin-top: -1px;
}
.mat-menu-panel {
    min-width: 145px !important;
    border-radius: 2px !important;
}

/* ======= Profile Dropdown End =========== */


/* ============== Margin Class Start ============== */

.mt-0{ margin-top: 0rem !important;}
.mt-1{ margin-top: 0.25rem !important;}
.mt-2{ margin-top: 0.5rem !important;}
.mt-3{ margin-top: 1rem !important;}
.mt-4{ margin-top: 1.5rem !important;}
.mt-5{ margin-top: 3rem !important;}

.mb-0{ margin-bottom: 0rem !important;}
.mb-1{ margin-bottom: 0.25rem !important;}
.mb-2{ margin-bottom: 0.5rem !important;}
.mb-3{ margin-bottom: 1rem !important;}
.mb-4{ margin-bottom: 1.5rem !important;}
.mb-5{ margin-bottom: 3rem !important;}

.pb-3{ padding-bottom: 1rem !important;}
.pb-4{ padding-bottom: 1.5rem !important;}
.pb-5{ padding-bottom: 3rem !important;}

/* ============== Margin Class End ============== */




/* ======= Side Menu Start =========== */

.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
    padding: 8px 8px 8px 0px !important;
    display: block !important;
    /* line-height: 40px !important; */
    margin-bottom: 5px !important;
}

.mat-list-base .mat-list-item {
    font-size: 13px !important;
    font-weight: 500;
}

.mat-list-base .mat-list-item, .mat-list-base .mat-list-option{
    /* height: 42px !important; */
    height: auto !important;
}

.material-icons{
    font-size: 18px !important;
}

.mat-icon{
    height: 18px !important;
    width: 18px !important;
    /* line-height: 40px !important; */
}

.nested-menu .expand.submenu {padding: 0px 0px 0px 0px !important; margin: 0px 0px 0px 10px !important; line-height: 9px !important; font-size: 13px;} 

.nested-menu .expand.submenu li a:hover { color: #1976d2 !important; font-weight: 500;}

.mat-nav-list .mat-list-item:focus{
    background-color: #272468 !important;
    color: #ffffff !important;
    border-radius: 2px;  
}

.mat-nav-list .mat-list-item:hover{     
    background-color: #272468 !important;
    color: #ffffff !important;
    border-radius: 4px;
}

.mat-nav-list .mat-list-item .mat-icon {
    color: #272468;
}

.mat-nav-list .mat-list-item .mat-icon:hover {
    color: #ffffff !important;
}

.mat-nav-list .mat-list-item:hover .mat-icon {
    color: #ffffff !important;
}

.active .mat-icon {
    color: #ffffff !important;
}


/* ======= Side Menu End =========== */








/* ======= Dashboard Start =========== */
.card {
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0 1px 2px rgba(56,65,74,.15);
    box-shadow: 0 1px 2px rgba(56,65,74,.15);

    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: 0.25rem;
}

.card-header{ padding: 1rem; border-bottom:dashed 1px #ccc;}
.brb{ border-bottom: none;}

.card-title {
    font-size: 17px !important;
    margin: 0px !important;
    font-weight: 500 !important;
}

.card-animate {
    -webkit-transition: all .4s;
    transition: all .4s;
}
.card-animate:hover {
    -webkit-transform: translateY(calc(-1.5rem / 5));
    transform: translateY(calc(-1.5rem / 5));
    -webkit-box-shadow: 0 5px 10px rgba(30,32,37,.12);
    box-shadow: 0 5px 10px rgba(30,32,37,.12);
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    color: var(--vz-card-color);
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}

.fw-medium {
    font-weight: 500;
}
.text-muted {
    --vz-text-opacity: 1;
    color: #878a99!important;
}

.mb-0 {
    margin-bottom: 0!important;
}

.avatar-sm {
    height: 3rem;
    width: 3rem;
    background-color: rgba(41,156,219,.18)!important;
    border-radius: 50%;
    text-align: center;
    line-height: 52px;
}

.avatar-sm i {
    font-size: 20px;
    /* color: #2c26a5; */
}

.mt15{
    margin-top: 15px;
}

.picon { font-size: 25px !important;}


.blue{color: #0000FF;}
.gray{color: #292d30;}
.green{color: #00870a;}
.red{color: #FF0000;}
.info{color: #17a2b8;}
.yellow{color: #efb300;}
.orange{color: #ff6544;}
.maroon{color: #800000;}
.purple{color: #800080;}

.editcls{color: green;cursor: pointer;}
.delcls{color: red;cursor: pointer;}


/* .blue-bg { background-color: #e5f6ff;}
.org-bg { background-color: #fff0e9;}
.pur-bg { background-color: #eee9fb;}
.grn-bg { background-color: #e2f6e9;}
.yel-bg { background-color: #fef5dc;}
.re-bg { background-color: #ffe5f6;} */




/* ======= Dashboard End =========== */




@media screen and (max-width: 768px) {

.hidden-sm  { display: block !important;}
.cdk-overlay-pane{width: 95% !important;}

}


/* ATTACHEMENT  */


.uplimg {
    text-align: left;
    width: 100%;

}


.uplimg img {
    height: 50px;
    width: 50px;
    border: solid 2px #bebbbb;
    background-color: #ffffff;
    padding: 5px;
}

.delicon {
    position: relative;
    top: -30px;
    left: 2px;
    color: red;
    cursor: pointer;
}

.uplheader {
    font-size: 11px;
    opacity: 1;
    color: #9b9b9b;
}

.attchmentcls{
    border-bottom: solid 1px #ccc;
    padding-bottom: 0px;
    margin-top: -10px;
    margin-bottom: 18px;
}

/* ================= TASK STATUS BUTTONS ===================== */

.statubutton {
    color: #ffffff;
    padding: 7px 15px;
    font-size: 13px;
    border-radius: 3px;
    white-space: nowrap;
    display: block;
    text-align: center;
  }
  
  .Completedcls{
    background-color: #08976d;
  }
  
  .workcls{
    background-color: #fd397a;
  }
  
  .pendingcls{
    background-color: #f1a400;
  
  }
  
  .closedcls{
    background-color: #ff0000;
  }
  
  .iconcls{
    background-color: #fefefe;
    border: solid 1px #cccccc;
    padding: 4px;
    float: left;
    display: block;
  }

  .gridimg{
    padding: 3px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
    height: 45px;
}


















